html {
  box-sizing: border-box;
  font-size: 18px;
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: 'ProximaNova-Regular', Helvetica, Arial, sans-serif;
}

html,
body,
#root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
}

*:focus-visible {
  outline: #369fff solid 2px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: 'ProximaNova-Semibold', Helvetica, Arial, sans-serif;
}

p {
  margin: 0;
  padding: 0;
  font-family: 'ProximaNova-Regular', Helvetica, Arial, sans-serif;
}

/*
* react-checkbox-tree
*/
.react-checkbox-tree .rct-node {
  position: relative;
}
.react-checkbox-tree .rct-node .rct-text .rct-title {
  margin-left: 22px;
}
.react-checkbox-tree .rct-node.rct-node-leaf .rct-text .rct-title {
  margin-left: 6px;
}
.react-checkbox-tree .rct-collapse-btn {
  position: absolute;
  left: 18px;
  top: 0;
  cursor: pointer;
  padding: 0 4px;

  border: none;
  margin: 0;
  width: auto;
  overflow: visible;
  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}
.react-checkbox-tree .rct-collapse-btn:active {
  outline: none;
}
.react-checkbox-tree .rct-icon.rct-icon-expand-open::before {
  content: '\25BE';
}
.react-checkbox-tree .rct-icon.rct-icon-expand-close::before {
  content: '\25B8';
}

.MuiInputBase-root:hover fieldset {
  border-color: #c4cdd5 !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #369fff !important;
}
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #de3618 !important;
}
.MuiFormHelperText-root.Mui-error {
  color: #bf0711 !important;
}

.hide-vertical-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-vertical-scrollbar::-webkit-scrollbar {
  display: none;
}
